/* Automate Marketplace */

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.woff2") format('woff2'),
       url("../fonts/Roboto-Bold.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.woff2") format('woff2'),
       url("../fonts/Roboto-Medium.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.woff2") format('woff2'),
       url("../fonts/Roboto-Regular.woff") format('woff');
  font-display: swap;
}
.automate_marketplace{
  background:  #F2FFFA 0% 0% no-repeat padding-box;
}
.double_app_automate_new .automate_marketplace{
  background: #F2FFFA !important;
}
.double_app_automate_new .header.sticky {
  background: #fff!important;
}
.new-app-integration-single-automate{
  background: #F2FFFA 0% 0% no-repeat padding-box !important;
}
.new_double_button button{
  color: #FFFFFF !important;
  font-size: 15px !important;
  border: 1px solid #2468f6;
  border-radius: 6px;
  font-family: Gordita-Regular;
  height: 46px !important;
  background: #2468F6 !important;
  min-width: 130px;
  cursor: pointer;
}
.comming_soon_button_double button{
  color: #e57700;
  font-size: 15px !important;
  border: 1px solid #e57700;
  background: #FFFFFF;
}
.marketplace_autoamte_apps {
  width: 100%;
  float: left;
  margin-bottom:0px;
  padding-top:40px;
  padding-bottom:40px;
}
.autoamte_apps_header {
  width: 100%;
  float: left;
}
.autoamte_apps_header h1 {
  font-family: "Gordita-Medium";
  font-size: 42px;
  color: #000000;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}
.container_trial .autoamte_apps_header p {
  text-align: center;
  margin-bottom:30px;
  color:#193A5D;
  font-size: 24px;
  line-height: inherit;
}
.container_trial .apps_header_left_container p {
    text-align: center;
    width:100%;
    display:flex;
    justify-content: center;
    margin-bottom: 0px;
    font-size: 24px;
    line-height: inherit;
    color: #416183 !important;
  }
.autoamte_apps_header_left {
  width: 100%;
  float: left;
  min-height: 400px;
  -webkit-border-radius: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c4c2c2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding:7.5% 9%;
  height:100%;
}
.autoamte_apps_header_right {
    width: 100%;
    float: left;
    min-height: 400px;
    -webkit-border-radius: 6px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c4c2c2;
    border-radius: 6px;
    height:100%;
    padding-top:30px;
}
.autoamte_apps_header_right .search_marketpalce{
    margin-bottom:0px;
    margin-right:0px;
    padding:0px 15px;
}
.empty_space_clear{
    height:30px;
    display: flex;
    width:100%;
}
.search_apps_box {
  overflow-y: auto;
  min-height: 335px;
  max-height: 335px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
  gap: 20px;
  width: 100%;
  padding: 0px 15px;
  margin-bottom: 10px;
  place-content: flex-start;
}

.apps_list{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DAD9D9;
    border-radius: 6px;
    width: 100%;
    min-height: 132px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.apps_list a{
  width: 100%;
  float: left;
  text-align: center;
}
.apps_list a:hover{
  text-decoration: none;
}
.apps_list img{
    width:64px;
    height:64px;
    margin-top: 17px;
}
.marketplace_autoamte_apps .col-md-6 {
  flex: 0 0 49%;
  max-width: 49%;
}
.autoamte_apps_selection{
    display: flex ;
    box-shadow: none;
    -webkit-box-shadow: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DAD9D9;
    border-radius: 6px;
    width: 155px;
    height: 155px;
    margin-bottom:30px;
    align-self: center;
    justify-content: center;
    place-items: center;
    flex-direction: column;
}
.autoamte_apps_selection img{
    width: 64px;
    height: 64px;
}
.apps_header_left_container{
    width: 100%;
    max-width: 95%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.autoamte_apps_header_right .search_marketpalce .search_click{
    padding-left: 40px;
    font-size: 20px;
    height: 60px;
    border: 1px solid #C4C2C2;
    border-radius: 6px;
    color:#333;
    
}
.search_click_header_single_app{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #00000029 !important;

}
.search_click_header_single_app::placeholder{
  color:#7A94AF !important;
}

.autoamte_apps_header_right .search_marketpalce .search_icon {
  position: absolute;
  left: 18px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
  background: inherit;
  height: 60px;
}

.search_apps_box::-webkit-scrollbar {
    width: 4px;
}
.search_apps_box::-webkit-scrollbar-thumb {
    background: #7A94AF;
    height: 20%;
    border-radius: 2px;
}
.search_apps_box::-webkit-scrollbar-track {
    background: #fff;
}
.anchor_text_span{
    display: block;
    padding: 10px 4px 8px;
    line-height: inherit;
    text-align: center;
    color: #000;
    font-size: 12px;
    font-family: Gordita-Regular;
}
.market_apps_conainer{
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.center_connection{
    overflow: hidden;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 4.8%;
    height: 64px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000029;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.center_connection svg{
    width:22px;
    height:22px;
}
.apps_list h6{
    margin-bottom:0px;
    font-family: "Roboto-Regular";
    font-size: 18px;
    color:#000000;
    margin-top: 0px;
    padding-top: 6px;
    padding-bottom: 10px;
    text-align: center;
    line-height: inherit;
}
.autoamte_apps_selection h6{
    margin-bottom:0px;
    font-family: "Gordita-Medium";
    font-size: 18px;
    color:#000000;
    margin-top:12px;
    line-height: inherit;
}
.content_center_automate{
  width:100%;
  float: left;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top:30px;
}
.container_trial .content_center_automate p{
  color:#000;
  font-size: 18px;
  margin-top:0px;
  margin-bottom:0px;
  font-family: "Roboto-Medium";
  text-align: center;
}
.container_trial .content_center_automate .form_automate p{
  margin-top:0px;
}
.container_trial .content_center_automate p a{
  color:#2468F6;
}
.container_trial .content_center_automate p a:hover{
 color:#467df5;
 text-decoration: none;
}

.am_cards_container{
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #C4C2C2;
border-radius: 6px;
width: 100%;
float: left;
padding:20px;
display: flex;
flex-direction: column;
flex: 1 1 auto;
height: 100%;
}
.am_cards_img_wrapper{
  width:100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description_receipe_list{
  margin-left: 6px !important;
}
.am_cards_img{
background: #F4F4F4 0% 0% no-repeat padding-box;
border-radius: 6px;
width: max-content;
padding: 0px 10px;
display: flex;
align-items: center;
height: 60px;
}
.am_cards_img span.space_apps{
margin:0px 5px;
}
.am_cards_img span img{
  width:42px;
  height:42px;
}
.am_cards_img span svg{
  width: 10px;
  height: 10px;
  fill: #416183;
}
.am_cards_button{
  width:auto;
}
.am_cards_button button{
color: #2468F6;
font-size: 16px;
border: 1px solid #2468F6;
border-radius: 6px;
font-family: "Gordita-Regular";  
height:48px;
background: #fff;
min-width: 130px;
cursor: pointer;
}
.am_cards_container h5{
  font-family: "Gordita-Regular";  
  color: #333333;
  width:100%;
  float:left;
  margin-top:10px;
  margin-bottom:0px;
  font-size: 22px;
  line-height: inherit;
  font-weight: 600;
}
.search_icon_single_app svg{
  color:  #474747 !important;
  fill:  #474747!important;
}
.search_icon_single_app_automate_2023 svg{
  color:  #474747 !important;
  fill:  #474747!important;
}
.am_cards_wrapper .autoamte_apps_header_right{
  height: inherit;
  border: none;
  padding-top: 0px;
  min-height: inherit;
  background: inherit;
}
.am_cards_wrapper .autoamte_apps_header_right .search_marketpalce{
  padding:0px;
}
.am_cards_wrapper .autoamte_apps_header_right .search_marketpalce .search_icon{
padding:0px;
position: absolute;
    left: 10px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;
}
.am_cards_wrapper .autoamte_apps_header_right .search_marketpalce .search_click{
  padding-left:50px;
  box-shadow: none;
}

.am_cards_wrapper h2{
    font-family: "Gordita-Medium";
    font-size: 36px;
    color: #333333;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
    margin-top:40px;
    float: left;
    line-height: inherit;
}
.am_cards_wrapper h3{
  font-family: "Gordita-Medium";
  font-size: 42px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  margin-top:40px;
  float: left;
  line-height: inherit;
}
.double_new_app_popular{
  margin: 30px 0 !important;
}
.double_app_button_workflow{
  min-width: 140px !important;
  height: 46px!important;
  margin-bottom: 14px;
  padding-top: 5px;
}
.am_cards_wrapper.actions_wrapper h2{
  margin-top: 0px;
}
.card_double_app_automate{
  padding-bottom: 30px !important;
}
.trigger_action_two_apps{
  margin-bottom: 30px !important;
}
.button_single_app_new{
  padding-top:4px;
}
.am_cards_wrapper{
  width: 100%;
    float: left;
    background: #F8F8F8;
    padding:40px 0px;
    margin-bottom: 0px;
}
.am_cards_column{
  justify-content: space-between;
  flex-wrap: wrap;
  width:100%;
}
.am_cards_column_left.col-lg-6{
  flex: 0 0 49%;
  max-width: 49%;
  margin-bottom:20px;
}

.am_cards_button button.soon_btn_bg {
  color: #E57700;
  font-size: 16px;
  border: 1px solid #E57700;
  cursor: default;
}
.am_cards_button button.soon_btn_bg.button_single_app_new:focus{
  outline: none !important;
}
.show_morw_option{
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  margin-top:20px;
}
.show_morw_option span{
  color: #33906B;
  font-family: "Roboto-Medium";
  font-size: 28px;
  cursor:pointer;
}

.am_cards_wrapper.actions_wrapper{
  width:100%;
  float:left;
  background: #fff;
  padding:0px;
  margin-top:50px;
  margin-bottom:50px;
}
.am_cards_wrapper.actions_wrapper h3 {
  font-family: "Gordita-Medium";
  font-size: 42px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  margin-top: 0px;
  float: left;
  line-height: inherit;
}
.am_cards_wrapper.actions_wrapper p{
  color:#416183;
  font-size: 24px;
  line-height: inherit;
  width:100%;
  float: left;
  text-align: center;
  margin-bottom: 30px;
}

.actions_wrapper_left.col-lg-6{
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 6px;
  flex: 0 0 49%;
  max-width: 49%;
  padding:20px;
}

.actions_wrapper_container h4{
  color: #333333;
  font-size: 30px;
  width:100%;
  float: left;
  display: flex;
  align-items: center;
  margin-bottom:15px;
  font-family: "Gordita-Medium";
  line-height: inherit;
}
.actions_wrapper_container h4 span{
  width:60px;
  height:60px;
  margin-right:10px;
  display: flex;
  align-items: center;
}
.am_cards_wrapper.actions_wrapper .am_cards_column_left{
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  height:max-content;
  margin-bottom: 0px;
}
.am_cards_wrapper.actions_wrapper .actions_wrapper_container {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 6px;
  width: 100%;
  float: left;
  padding: 20px;
  padding-bottom:0px;
  border: 1px solid #DAD9D9;
}
.am_cards_wrapper.actions_wrapper .am_cards_column_left .am_cards_container{
  margin-bottom:20px;
}

.actions_wrapper_container h5{
  font-family: "Gordita-Medium";  
  color: #333333;
  width:100%;
  float:left;
  margin-top:0px;
  margin-bottom:0px;
  font-size: 24px;
  line-height: inherit;
}
.why_autoamte_wrapper .footer_form_container{
  background: #E7FDF4 0% 0% no-repeat padding-box !important;
}
.actions_wrapper_container h6{
  font-family: "Gordita-Regular";  
  color: #416183;
  width:100%;
  float:left;
  margin-top:5px;
  margin-bottom:0px;
  font-size: 20px;
  line-height: inherit;
}
.am_cards_wrapper.actions_wrapper .am_cards_column_left .am_cards_container:last-child{
  margin-bottom:0px;
}
.actions_wrapper_container .am_cards_container{
  position: relative;
}
.actions_wrapper_container .am_cards_button{
  position: absolute;
  right:20px;
  top:15px;
}
.actions_wrapper_container .am_cards_container .am_cards_button button.soon_btn_bg{
height:32px;
}
.actions_wrapper_container .am_cards_container{
  display: flex;
flex-direction: row;
}
.actions_wrapper_container .am_cards_container .am_cards_content{
  margin-left:20px;
  width: 85%;
  margin-top:-3px;
}
.am_cards_container._responsive_mode{
  flex-direction: column;
}
.actions_wrapper_container .am_cards_container._responsive_mode .am_cards_img{
  height: max-content;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom:10px;
}
.am_cards_container._responsive_mode .am_cards_img span{
  width: 44px;
  height: 44px;
  background: #F4F4F4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.actions_wrapper_container .am_cards_container._responsive_mode .am_cards_content{
  margin-left:0px;
}
.actions_wrapper_container .am_cards_container._responsive_mode  .am_cards_button{
  position: relative;
  right: 0;
  top: 0;
}

.why_autoamte_wrapper{
  width:100%;
  float:left;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding: 70px 0px 50px;
  margin-bottom:60px;
}
.why_automate_wrappper_integration_update{
  margin-top: 50px;
}
.why_autoamte_wrapper .row{
  justify-content: space-between;
}
.automate_marketplace .footer_form{
  margin-bottom:60px;
}
.automate_marketplace .apps_integrations.bg_marketpalce{
  margin-top:60px;
}
.automate_marketplace .apps_integrations.bg_marketpalce .cogni_fav_apps {
  margin-bottom: 60px;
}
.three_steps_wrapper{
  display: flex;
  align-items: center;
  margin-bottom:30px;
}
.three_steps_wrapper:last-child{
  margin-bottom:0px;
}
.three_steps_left{
  margin-right:20px;
}
.three_steps_left{
  align-self:flex-start;
}
.why_autoamte_wrapper .col-md-6{
  flex: 0 0 49%;
    max-width: 49%;
}
.three_steps_wrapper img{
  width:100px;
  height:100px;
}
.three_steps_right h5{
  font-family: "Roboto-Medium";  
  color: #000;
  width:100%;
  float:left;
  margin-top:0px;
  margin-bottom:0px;
  font-size: 30px;
  line-height: inherit;
}
.container_trial .three_steps_right p{
  font-family: "Roboto-Regular";  
  color: #416183;
  width:100%;
  float:left;
  margin-top:5px;
  margin-bottom:0px;
}
.why_autoamte_wrapper h3{
  font-family: "Roboto-Medium";
    font-size: 42px;
    color: #000;
    margin-bottom: 0px;
    text-align: left;
    width: 100%;
    float: left;
    line-height: inherit;
}
.am_cards_container._responsive_mode{
  display: none;
}
.am_cards_wrapper.actions_wrapper .am_cards_column_left .am_cards_container:last-child{
  margin-bottom:0px;
}
.container_trial .why_autoamte_wrapper p{
  color:#416183;
  font-size: 24px;
  line-height: inherit;
  width:100%;
  float: left;
}

/* Selected Apps */
.explore_apps_container{
  width:100%;
  float: left;
  margin-bottom:60px;
}
.explore_apps_container .col-md-6{
  flex: 0 0 49%;
    max-width: 49%;
}
.explore_apps_container .col-md-6:first-child{
  padding-left:0px;
}
.explore_apps_container .col-md-6:last-child{
  padding-right:0px;
}
.explore_apps_container .row{
width: 100%;
display: flex;
justify-content: space-between;
padding: 0px;
margin:0px;
}
.selected_apps_container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom:20px;
  align-items: center;
}
.selected_apps_left_border{
  width: 180px;
  min-height: 180px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DAD9D9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.selected_apps_left_border img{
  width:84px;
  height:84px;
}
.selected_apps_left_border h6{
  font-family: "Gordita-Regular";  
  margin-bottom:0px;
  margin-top:10px;
  color:#000000;
  font-size:20px;
  text-align: center;
  line-height: inherit;
  padding:0px 10px;
}
.selected_center_connection{
  width: 84px;
  height: 68px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #00000029;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected_center_connection span svg{
  width: 18px;
  height: 18px;
  fill:#193A5D
}
.new_plus_icon_single svg{
  fill:#193A5D
}

.content_center_automate_selected{
  width:100%;
  float: left;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin-top:10px;
}
.content_center_automate{
  width:100%;
  float: left;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.form_automate{
  width: 100%;
  float: left;
  padding: 0px 25%;
}
.form_automate input {
  height: 52px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #B7B7B7;
width:60%;
padding:0px 20px;
outline:0;
margin:20px 0px;
font-size:18px;
color:#333;
font-family: "Roboto-Regular";
}
.form_automate button{
  height: 52px;
  background: #B5FFE3 0% 0% no-repeat padding-box;
  border: 1px solid #15C381;
  margin-left: 20px;
  outline:0;
  cursor:pointer;
  font-family: "Roboto-Medium";
  font-size: 16px;
  color:#000;
}
.form_automate button:focus{
  background: #B5FFE3 0% 0% no-repeat padding-box;
  border: 1px solid #15C381;
  outline: 0;
}
.container_trial .content_center_automate_selected p{
  color:#000;
  font-size: 18px;
  margin-top:0px;
  margin-bottom:0px;
  font-family: "Roboto-Medium";
  text-align: center;
}
.container_trial .content_center_automate_selected p a{
  color:#099A63;
}
.container_trial .content_center_automate_selected p a:hover{
  color:#099A63;
  text-decoration: none;
}
.autoamte_apps_header.autoamte_apps_header_selcted{
  padding:0px 15%;
}
.container_trial .autoamte_apps_header.autoamte_apps_header_selcted p{
  margin-bottom:10px;
}
.explore_apps_box{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #00000029;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.explore_apps_box h4 img{
  widtH:16%;
  height:16%;
  margin-right:15px;
}
.explore_apps_container h3 {
  font-family: "Gordita-Medium";
  font-size: 42px;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  float: left;
  line-height: inherit;
}
.explore_apps_container .explore_apps_box h4 {
  font-family: "Gordita-Medium";
  font-size: 36px;
  color: #000;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explore_apps_box_img{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  padding: 8% 0px;
  display: flex;
  align-items: center;
}
.container_trial .explore_apps_box p{
color: #193A5D;
padding:50px;
text-align: center;
margin-bottom:0px;
padding-top:20px;
font-size:24px;
line-height: inherit;
}
.container_trial .automate_marketplace .footer_form_left .main-slider-left p {
  font-size:24px;
  line-height: inherit;
}

/* .am_cards_wrapper .container{
  width: 70%;
} */

.marketplace_header_container h1 span{
  display: block;
}

.marketplace_header_container p{
padding:0px 12%;
}




.am_cards_wrapper.actions_wrapper .actions_wrapper_container {
  position: relative;
}
.am_cards_wrapper.actions_wrapper .actions_wrapper_container  h4{
 
  background: inherit;
  z-index: 9;
  padding: 12px 0px;
  margin-bottom: 0px;
  align-items: center;

}
.am_cards_wrapper.actions_wrapper .actions_wrapper_container{
  padding-top:8px;
}
.mobile_cards{
  width:100%;
  float:left;
}
.mobile_cards_width{
  width:100%;
  float:left;
}
.mobile_cards_width .am_cards_img_wrapper{
  justify-content: center;
}
.mobile_cards_width .am_cards_container h5{
  text-align: center;
  margin-bottom:12px;
}
.mobile_cards_width .am_cards_button{
  text-align: center;
}
.mobile_cards_width .am_cards_container {
  padding: 30px 20px;
}
.mobile_cards_width .am_cards_container{
  display: none;
}
.search_icon svg{
  width:18px;
  height:18px;
}
@media only screen and (max-width:767px)
{
  .am_cards_container{
    display: none;
  }
  .actions_wrapper_container .am_cards_container .am_cards_content{
    width: 82%;
    margin-top: 0px;
    float: right;
    display: flex;
    flex-direction: column;
  }
  .actions_wrapper_container .am_cards_container .am_cards_content {
    margin-left: 10px;
}
.actions_wrapper_container .am_cards_container._responsive_mode .am_cards_img{
  float:left;
width:44px;
height:44px;
}
.mobile_cards_width .am_cards_container{
  display: block;
}
.actions_wrapper_container .am_cards_container._responsive_mode .am_cards_button{
  margin-top:12px;
}
.mobile_view_tab .mob_accordian.mob_accordian_faq .container h2 {
  justify-content: left;
  padding-left: 15px;
  padding-right: 15px;
}
.actions_wrapper_container .am_cards_container._responsive_mode .am_cards_content {
  margin-left: 0px;
  padding-left: 10px;
}
.mobile_cards_width .am_cards_button.center_width_100{
  width: 100%;
}
.mobile_cards_width .am_cards_button.center_width_100 button{
  width: 100%;
  float:left;
}
}

.am_cards_column .no-search-items {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
    margin-top: 0px;
    float: left;
    line-height: inherit;
}
.single-app-logo{
  width:20%;
}
.new-single-app-container svg{
  width:4%;
  fill: #BABABA;
}
.new-app-integration-single-automate{
  background: #F2FFFA 0% 0% no-repeat padding-box !important;
}
.search_marketplace_integrations_automate .search_click_integrations_automate{
  padding: 13px 15px 13px 40px !important;
}
.search_marketplace_integrations_automate .search_click_integrations_automate::placeholder{
  color:#BABABA !important
}
.search_marketplace_integrations_automate .search_icon{
  top: 5px !important;
}
.color-bababa{
 color: #BABABA !important;
}